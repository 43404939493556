@import "https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css";

* {
    box-sizing: border-box;
}

.result-card {
    width: 100%;
    display: flex;
    height: 150px;
    padding: 15px;
    justify-content: space-between;
    border-radius: 10px;
    background-color: hsl(171, 100%, 41%);
}
.result-card:not(:last-child) {
    margin-bottom: 10px;
}
.result-card__btn {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.result-card__img {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.result-card__img img {
    width: 80px;
}
.result-card__info {
    width: 55%;
}
.board {
    width: 100%;
    margin: "auto";
}
.searchbar {
    display: flex;
    justify-content: center;
    align-items: center;
}
.notification {
    position: sticky;
    top: 0;
    z-index: 5000;
}
